<template>
  <v-text-field
    v-model="phone"
    v-bind="$attrs"
    v-mask="'+38 (0##) ###-##-##'"
    :error-messages="phoneErrors"
    @blur="blur"
    @input="input"
  >
    <template v-for="slot of Object.keys($slots)" v-slot:[slot]>
      <slot :name="slot" />
    </template>
  </v-text-field>
</template>

<script>
import { mask } from 'vue-the-mask'
import { validationMixin } from 'vuelidate'
import { minLength } from 'vuelidate/lib/validators'
import { formatPhoneNumber } from '@/helpers/formatPhoneNumber'

export default {
  name: 'PhoneEdit',

  inheritAttrs: false,

  directives: { mask },

  mixins: [validationMixin],

  emits: ['change', 'validation'],

  props: {
    value: {
      type: String,
      default: '',
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
    validate: {
      type: Boolean,
      default: false,
    },
    isTouched: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      phone: '',
    }
  },

  validations() {
    return {
      phone: { minLength: minLength(19) },
    }
  },

  created() {
    this.initialize()
  },

  watch: {
    value(val) {
      const formatted = formatPhoneNumber(val)
      if (this.phone !== formatted) {
        this.phone = formatted
      }
    },
    isTouched(val) {
      if (val) {
        if (!this.phone) {
          this.phone = '+38 (0'
        }
      } else {
        this.$v.phone.$reset()
      }
    },
  },

  computed: {
    phoneErrors() {
      const errors = []
      if (!this.$v.phone.$dirty || !this.validate) {
        this.$emit('validation', true)
      } else {
        const error = !this.$v.phone.minLength
        error && errors.push('Введіть коректний номер')
        this.$emit('validation', !error)
      }
      return errors
    },
  },

  methods: {
    initialize() {
      if (this.value) {
        this.phone = formatPhoneNumber(this.value)
      }
      this.$v.phone.$touch()
    },

    blur() {
      this.validate && this.$v.phone.$touch()
    },

    input(val) {
      this.validate && this.$v.phone.$touch()
      this.$emit('change', val.replace(/[\s()-]/g, ''))
    },
  },
}
</script>
