<template>
  <v-dialog v-model="open" @click:outside="$emit('cancel')" @keydown.esc="$emit('cancel')" persistent max-width="600px">
    <v-card>
      <v-card-title>
        <span class="text-h5">{{ addNew ? 'Створення' : 'Редагування' }} контакту</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="8" md="6">
              <v-select
                v-model="model.type"
                :items="types"
                item-text="name"
                item-value="value"
                @input="$v.model.type.$touch()"
                @blur="$v.model.type.$touch()"
                :error-messages="typeErrors"
              >
                <template #label>
                  <span class="red--text"><strong>* </strong></span>Тип контакту
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" sm="8" md="6">
              <v-text-field
                v-model="model.value"
                @input="$v.model.value.$touch()"
                @blur="$v.model.value.$touch()"
                :error-messages="contactErrors"
              >
                <template #label>
                  <span class="red--text"><strong>* </strong></span>Контакт
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="submit">Ok</v-btn>
        <v-btn text @click="$emit('cancel')">Скасувати</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import cloneDeep from '@/mixins/cloneDeep'
import { required } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import { mapState } from 'vuex'

export default {
  name: 'ContactItemEdit',

  props: {
    open: {
      type: Boolean,
      default: () => false,
    },
    addNew: {
      type: Boolean,
      default: () => false,
    },
    contact: {
      type: Object,
      default: () => null,
    },
    contactType: {
      type: String,
      default: 'technical',
    },
    editCard: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['ok', 'cancel'],
  mixins: [cloneDeep, validationMixin],

  validations: {
    model: {
      type: { required },
      value: { required },
    },
  },

  data() {
    return {
      model: null,
    }
  },

  created() {
    this.initialize()
  },

  watch: {
    editCard(val) {
      if (!val) this.initialize()
    },
    open(val) {
      if (val) this.$v.$reset()
    },
  },

  computed: {
    ...mapState('dictionaries', ['technicalContactTypesDictionary', 'personalContactTypesDictionary']),
    types() {
      return this.contactType === 'technical' ? this.technicalContactTypesDictionary : this.personalContactTypesDictionary
    },
    hasErr() {
      return this.$v.$anyError
    },

    typeErrors() {
      let errors = []
      if (!this.$v.model.type.$dirty) return errors
      !this.$v.model.type.required && errors.push("Це поле обов'язкове")
      return errors
    },

    contactErrors() {
      let errors = []
      if (!this.$v.model.value.$dirty) return errors
      !this.$v.model.value.required && errors.push("Це поле обов'язкове")
      return errors
    },
  },

  methods: {
    initialize() {
      this.model = this.addNew ? { type: '', value: '' } : this.cloneObjectDeep(this.contact)
    },
    submit() {
      this.$v.model.$touch()
      if (!this.hasErr) {
        const result = { ...this.model }
        this.model = { type: '', value: '' }
        this.$v.model.$reset()
        this.$emit('ok', result)
      }
    },
  },
}
</script>
