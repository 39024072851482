<template>
  <v-dialog v-model="open" @click:outside="$emit('cancel')" @keydown.esc="$emit('cancel')" persistent max-width="600px">
    <v-card>
      <v-card-title>
        <span class="text-h5">{{ addNew ? 'Створення' : 'Редагування' }} персонального контакту</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="8" md="6">
              <v-text-field
                v-model="model.full_name"
                label="П.І.Б. персони"
                @input="$v.model.full_name.$touch()"
                @blur="$v.model.full_name.$touch()"
                :error-messages="nameErrors"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="8" md="6">
              <v-text-field
                v-model="model.position"
                label="Посада"
                @input="$v.model.position.$touch()"
                @blur="$v.model.position.$touch()"
                :error-messages="positionErrors"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="8" md="6">
              <PhoneEdit
                :value="model.phone"
                :is-touched="isPhoneTouched"
                label="Телефон"
                validate
                @change="(val) => (model.phone = val)"
                @validation="(val) => (isPhoneValid = val)"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="submit">Ok</v-btn>
        <v-btn text @click="$emit('cancel')">Скасувати</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import cloneDeep from '@/mixins/cloneDeep'
import { validationMixin } from 'vuelidate'
import { maxLength, minLength, required } from 'vuelidate/lib/validators'
import PhoneEdit from '@/components/common/PhoneEdit'

export default {
  name: 'PersonEdit',
  components: { PhoneEdit },

  props: {
    open: {
      type: Boolean,
      default: () => false,
    },
    addNew: {
      type: Boolean,
      default: () => false,
    },
    person: {
      type: Object,
      default: () => null,
    },
    editCard: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['ok', 'cancel'],
  mixins: [cloneDeep, validationMixin],

  validations: {
    model: {
      full_name: { required },
      position: { required },
      phone: { required, minLength: minLength(13), maxLength: maxLength(13) },
    },
  },

  data() {
    return {
      model: null,
      isPhoneValid: false,
      isPhoneTouched: false,
    }
  },

  created() {
    this.initialize()
  },

  watch: {
    editCard(val) {
      if (!val) this.initialize()
    },
    open() {
      this.$v.model.$reset()
      this.isPhoneValid = false
    },
  },

  computed: {
    nameErrors() {
      let errors = []
      if (!this.$v.model.full_name.$dirty) return errors
      !this.$v.model.full_name.required && errors.push("Це поле обов'язкове")
      return errors
    },

    positionErrors() {
      let errors = []
      if (!this.$v.model.position.$dirty) return errors
      !this.$v.model.position.required && errors.push("Це поле обов'язкове")
      return errors
    },

    hasErr() {
      return this.$v.$anyError || !this.isPhoneValid
    },
  },

  methods: {
    initialize() {
      this.model = this.addNew ? { full_name: '', position: '', phone: '' } : this.cloneObjectDeep(this.person)
    },
    submit() {
      this.$v.model.$touch()
      this.isPhoneTouched = true
      if (this.hasErr) return
      this.$emit('ok', this.model)
    },
  },
}
</script>
