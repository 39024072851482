<template>
  <div>
    <v-hover v-slot="{ hover }">
      <div
        v-show="editedPerson.full_name && editedPerson.position && editedPerson.phone"
        class="mt-2 ml-4 d-inline-flex flex-row justify-start align-center contact"
        :class="{ 'text--secondary': hover }"
      >
        <span class="w-200 font-weight-bold">{{ editedPerson.full_name }}</span>
        <span class="w-150">{{ editedPerson.position }}</span>
        <span class="max-w-300 font-weight-medium">{{ formatPhoneNumber(editedPerson.phone) }}</span>
        <div v-if="hasEditPermission" v-show="hover" class="ml-3">
          <v-tooltip top>
            <template v-slot:activator="{ on: on, attr: attr }">
              <v-icon class="action-icon" v-bind="attr" v-on="on" @click="openEditDialog = true"> mdi-pencil </v-icon>
              <PersonEdit
                :open="openEditDialog"
                :person="editedPerson"
                :editCard="editCard"
                @ok="(data) => savePerson(data)"
                @cancel="openEditDialog = false"
              />
            </template>
            <span>Редагувати</span>
          </v-tooltip>
          <v-dialog
            v-model="openDeleteDialog"
            @click:outside="openDeleteDialog = false"
            @keydown.esc="openDeleteDialog = false"
            persistent
            max-width="600px"
          >
            <template v-slot:activator="{ on: dialogOn, attr: dialogAttr }">
              <v-tooltip top>
                <template v-slot:activator="{ on: tooltipOn, attr: tooltipAttr }">
                  <v-icon class="action-icon" v-bind="{ ...dialogAttr, ...tooltipAttr }" v-on="{ ...dialogOn, ...tooltipOn }">
                    mdi-delete
                  </v-icon>
                </template>
                <span>Видалити</span>
              </v-tooltip>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">Підтвердження</span>
              </v-card-title>
              <v-card-text>
                <span class="text-h6">Видалити цю персону?</span>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="deletePerson">Так</v-btn>
                <v-btn text @click="openDeleteDialog = false">Нi</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </div>
    </v-hover>
    <div v-show="editedPerson.full_name && editedPerson.position && editedPerson.phone" class="py-2">
      <div class="d-flex flex-row">
        <div class="ml-4 font-weight-medium">Контакти</div>
        <v-tooltip v-if="hasEditPermission" top :open-on-focus="false">
          <template v-slot:activator="{ on, attrs }">
            <v-icon class="ml-2 action-icon" v-bind="attrs" v-on="on" @click="addNewContact = true">
              mdi-plus-circle-outline
            </v-icon>
          </template>
          <span>Додати контакт</span>
          <ContactItemEdit
            :open="addNewContact"
            contact-type="personal"
            :add-new="true"
            :types="types"
            :editCard="editCard"
            @ok="(data) => addContact(data)"
            @cancel="addNewContact = false"
          />
        </v-tooltip>
      </div>
      <div v-for="(type, index) in editedPerson.contact_type" :key="index">
        <ContactItem
          :contact="type"
          contact-type="personal"
          :editCard="editCard"
          :hasEditPermission="hasEditPermission"
          @save="(data) => saveContact(data, index)"
          @delete="deleteContact(index)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import cloneDeep from '@/mixins/cloneDeep'
import ContactItem from '@/components/partials/contacts/ContactItem'
import ContactItemEdit from '@/components/partials/contacts/ContactItemEdit'
import PersonEdit from '@/components/partials/contacts/PersonEdit'
import { formatPhoneNumber } from '@/helpers/formatPhoneNumber'

export default {
  name: 'PersonalContact',
  components: { PersonEdit, ContactItemEdit, ContactItem },

  props: {
    person: {
      type: Object,
      default: () => null,
    },
    editCard: {
      type: Boolean,
      default: false,
    },
    types: {
      type: Array,
      default: () => [],
    },
    hasEditPermission: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  emits: ['save', 'delete'],

  mixins: [cloneDeep],

  data() {
    return {
      openEditDialog: !this.person.full_name,
      openDeleteDialog: false,
      editedPerson: null,
      dialogModel: null,
      addNewContact: false,
    }
  },

  created() {
    this.initialize()
  },

  watch: {
    editCard(val) {
      if (!val) this.initialize()
    },
  },

  methods: {
    formatPhoneNumber,
    initialize() {
      this.editedPerson = this.cloneObjectDeep(this.person)
    },

    addContact(data) {
      if (!this.editedPerson.contact_type) this.editedPerson.contact_type = []
      this.editedPerson.contact_type.push(data)
      this.addNewContact = false
      this.$emit('save', this.editedPerson)
    },

    saveContact(data, index) {
      this.editedPerson.contact_type[index] = { ...data }
      this.$emit('save', this.editedPerson)
    },

    deleteContact(index) {
      const needToSave = !!this.editedPerson.contact_type[index].type
      this.editedPerson.contact_type.splice(index, 1)
      needToSave && this.$emit('save', this.editedPerson)
    },

    savePerson(data) {
      this.openEditDialog = false
      this.editedPerson = this.cloneObjectDeep(data)
      this.$emit('save', this.editedPerson)
    },

    deletePerson() {
      this.$emit('delete')
    },
  },
}
</script>

<style scoped>
.contact {
  height: 24px;
  cursor: default;
}

.w-150 {
  width: 150px;
}

.w-200 {
  width: 200px;
}

.max-w-300 {
  max-width: 300px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.action-icon {
  cursor: pointer;

  &:hover {
    color: black;
  }
}
</style>
