<template>
  <div>
    <v-hover v-slot="{ hover }">
      <div
        v-show="!!contact.type"
        class="ml-4 d-inline-flex flex-row justify-start align-center contact"
        :class="{ 'text--secondary': hover }"
      >
        <span class="w-350">
          {{ contactData.title }}
          <sup v-if="contact.type === 'telegram_id'">
            <v-btn
              href="https://docs.google.com/document/d/1iRAMyetDw1KrBY9FIdKhen7olu5u3NxbleDt3E1Mq2A/edit"
              target="_blank"
              icon
              small
            >
              <v-icon size="18" color="primary">mdi-information-outline</v-icon>
            </v-btn>
          </sup>
        </span>
        <a
          v-if="contactData.value.match(/^(http|https|ftp):\/\/(([A-Z0-9][A-Z0-9_-]*)(\.[A-Z0-9][A-Z0-9_-]*)+)/i)"
          class="max-w-300 overflow-hidden font-weight-medium"
          :href="contactData.value"
          target="_blank"
          >{{ contactData.value }}</a
        >
        <span v-else class="max-w-300 overflow-hidden font-weight-medium">{{ contactData.value }}</span>
        <div v-show="hover" class="ml-3">
          <v-tooltip v-if="hasEditPermission" top>
            <template #activator="{ on, attr }">
              <v-icon class="action-icon" v-bind="attr" v-on="on" @click="openEditDialog = true"> mdi-pencil </v-icon>
              <ContactItemEdit
                :contact="editedContact"
                :contact-type="contactType"
                :types="types"
                :open="openEditDialog"
                :editCard="editCard"
                @ok="(data) => saveContact(data)"
                @cancel="openEditDialog = false"
              />
            </template>
            <span>Редагувати</span>
          </v-tooltip>
          <v-dialog
            v-model="openDeleteDialog"
            @click:outside="openDeleteDialog = false"
            @keydown.esc="openDeleteDialog = false"
            persistent
            max-width="600px"
          >
            <template v-slot:activator="{ on: dialogOn, attr: dialogAttr }">
              <v-tooltip v-if="hasEditPermission" top>
                <template v-slot:activator="{ on: tooltipOn, attr: tooltipAttr }">
                  <v-icon class="action-icon" v-bind="{ ...dialogAttr, ...tooltipAttr }" v-on="{ ...dialogOn, ...tooltipOn }">
                    mdi-delete
                  </v-icon>
                </template>
                <span>Видалити</span>
              </v-tooltip>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">Підтвердження</span>
              </v-card-title>
              <v-card-text>
                <span class="text-h6">Видалити цей контакт?</span>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="deleteContact">Так</v-btn>
                <v-btn text @click="openDeleteDialog = false">Нi</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </div>
    </v-hover>
  </div>
</template>

<script>
import cloneDeep from '@/mixins/cloneDeep'
import ContactItemEdit from '@/components/partials/contacts/ContactItemEdit.vue'
import { mapState } from 'vuex'

export default {
  name: 'ContactItem',
  components: { ContactItemEdit },

  mixins: [cloneDeep],

  props: {
    contact: {
      type: Object,
      default: () => null,
    },
    contactType: {
      type: String,
      default: 'technical',
    },
    editCard: {
      type: Boolean,
      default: false,
    },
    hasEditPermission: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  emits: ['save', 'delete'],

  data() {
    return {
      editedContact: null,
      openEditDialog: false,
      openDeleteDialog: false,
    }
  },

  created() {
    this.initialize()
  },

  watch: {
    editCard(val) {
      if (!val) this.initialize()
    },
  },

  computed: {
    ...mapState('dictionaries', ['technicalContactTypesDictionary', 'personalContactTypesDictionary']),

    types() {
      return this.contactType === 'technical' ? this.technicalContactTypesDictionary : this.personalContactTypesDictionary
    },
    contactData() {
      const title = this.types.find((item) => item.value === this.editedContact.type)?.name
      return { ...this.editedContact, title }
    },
  },

  methods: {
    initialize() {
      this.editedContact = this.cloneObjectDeep(this.contact)
    },
    saveContact(data) {
      this.openEditDialog = false
      this.editedContact = this.cloneObjectDeep(data)
      this.$emit('save', this.editedContact)
    },

    deleteContact() {
      this.openDeleteDialog = false
      this.$emit('delete')
    },
  },
}
</script>

<style scoped>
.contact {
  height: 24px;
  cursor: default;
}

.max-w-300 {
  max-width: 300px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.w-350 {
  width: 350px;
}

.action-icon {
  cursor: pointer;
  &:hover {
    color: black;
  }
}
</style>
