<template>
  <v-container fluid>
    <div v-if="technicalContacts" class="px-5 py-4">
      <span class="subtitle-1 mb-2 font-weight-bold">Технічні контакти</span>
      <v-tooltip v-if="hasEditPermission" top :open-on-focus="false">
        <template v-slot:activator="{ on, attrs }">
          <v-icon class="ml-2 action-icon" v-bind="attrs" v-on="on" @click="addNewContact = true">
            mdi-plus-circle-outline
          </v-icon>
        </template>
        <span>Додати контакт</span>
        <ContactItemEdit
          :open="addNewContact"
          :add-new="true"
          contact-type="technical"
          @ok="(data) => addTechnicalContact(data)"
          @cancel="addNewContact = false"
        />
      </v-tooltip>
      <div v-for="(contact, index) in technicalContacts" :key="index">
        <ContactItem
          :contact="contact"
          contact-type="technical"
          :editCard="editCard"
          :hasEditPermission="hasEditPermission"
          @save="(data) => saveTechnicalContact(data, index)"
          @delete="deleteTechnicalContact(index)"
        />
      </div>
      <v-divider />
    </div>

    <div v-if="personalContacts" class="px-5 py-4">
      <span class="subtitle-1 mb-2 font-weight-bold">Персональні контакти</span>
      <v-tooltip v-if="hasEditPermission" top :open-on-focus="false">
        <template v-slot:activator="{ on, attrs }">
          <v-icon class="ml-2 action-icon" v-bind="attrs" v-on="on" @click="addNewPerson = true">
            mdi-plus-circle-outline
          </v-icon>
        </template>
        <span>Додати персону</span>
        <PersonEdit
          :open="addNewPerson"
          :addNew="true"
          :editCard="editCard"
          @ok="(data) => addPerson(data)"
          @cancel="addNewPerson = false"
        />
      </v-tooltip>
      <div v-for="(person, index) in personalContacts" :key="index">
        <PersonalContact
          :person="person"
          :editCard="editCard"
          :hasEditPermission="hasEditPermission"
          @save="(data) => savePerson(data, index)"
          @delete="deletePerson(index)"
        />
      </div>
    </div>
  </v-container>
</template>

<script>
import cloneDeep from '@/mixins/cloneDeep'
import convertAddress from '@/mixins/convertAddress'
import ContactItem from '@/components/partials/contacts/ContactItem'
import PersonalContact from '@/components/partials/contacts/PersonalContact'
import ContactItemEdit from '@/components/partials/contacts/ContactItemEdit'
import PersonEdit from '@/components/partials/contacts/PersonEdit'

export default {
  name: 'CardContacts',
  components: { PersonEdit, ContactItemEdit, PersonalContact, ContactItem },

  mixins: [cloneDeep, convertAddress],

  props: {
    currentItem: {
      type: Object,
      required: true,
      default: () => {},
    },
    isDataSaved: {
      type: Boolean,
      required: true,
      default: false,
    },
    currentTabName: {
      required: true,
      default: null,
    },
    editCard: {
      type: Boolean,
      default: false,
    },
    hasEditPermission: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data: function () {
    return {
      currentAddress: '',
      editedItem: null,
      currentTechnicalContacts: [],
      currentPersonalContacts: [],
      addNewContact: false,
      addNewPerson: false,
    }
  },

  computed: {
    technicalContacts() {
      if (Object.keys(this.editedItem.contacts).includes('technical')) {
        return this.editedItem.contacts.technical || []
      } else {
        return null
      }
    },
    personalContacts() {
      if (Object.keys(this.editedItem.contacts).includes('personal')) {
        return this.editedItem.contacts.personal || []
      } else {
        return null
      }
    },
  },

  created() {
    this.initialize()
  },

  watch: {
    isDataSaved(val) {
      if (val && this.currentTabName === 'contacts') {
        this.$emit('saveData', this.editedItem)
      }
    },
    editCard(val) {
      if (!val) this.initialize()
    },
  },

  methods: {
    initialize() {
      this.editedItem = this.cloneObjectDeep(this.currentItem)
      if (!this.editedItem.contacts) this.editedItem.contacts = { personal: [] }
    },
    addTechnicalContact(data) {
      if (!this.editedItem.contacts.technical) this.editedItem.contacts.technical = []
      this.editedItem.contacts.technical.push(data)
      this.addNewContact = false
      this.$emit('changes')
    },
    saveTechnicalContact(data, index) {
      this.editedItem.contacts.technical[index] = { ...data }
      this.$emit('changes')
    },
    deleteTechnicalContact(index) {
      const needToSave = !!this.editedItem.contacts.technical[index].type
      this.editedItem.contacts.technical.splice(index, 1)
      needToSave && this.$emit('changes')
    },
    addPerson(data) {
      if (!this.editedItem.contacts.personal) this.editedItem.contacts.personal = []
      this.editedItem.contacts.personal.push(data)
      this.addNewPerson = false
      this.$emit('changes')
    },
    savePerson(data, index) {
      this.editedItem.contacts.personal[index] = { ...data }
      this.$emit('changes')
    },
    deletePerson(index) {
      this.editedItem.contacts.personal.splice(index, 1)
      this.$emit('changes')
    },
  },
}
</script>

<style scoped lang="scss">
#coordinates {
  width: min-content;
  outline: none;
}
.map-control {
  width: 100%;
  outline: none;
  border-bottom: 1px solid #898b8c;
  height: 30px;
}
.action-icon {
  cursor: pointer;
  &:hover {
    color: black;
  }
}
</style>
