export default {
  data: () => ({
    addresses: [],
  }),

  computed: {
    item() {
      return this.servicePoint || this.partner || this.company
    },
    convertedAddress: {
      get() {
        return this.item?.address ? Object.values(this.item.address).slice(1, 5).join(', ') : ''
      },
      set(val) {
        if (val) {
          const addressArr = val.split(', ')
          this.editedItem.address.country = 'Україна'
          this.editedItem.address.city = 'Київ'
          this.editedItem.address.street = addressArr[2]
          this.editedItem.address.building_number = addressArr[3]
        }
      },
    },
    convertedAddresses: {
      get() {
        this.item.addresses.forEach((item) => {
          this.addresses.push({
            type: item.type,
            value: item.country && item.city && item.street ? Object.values(item).slice(1, 5).join(', ') : '',
          })
        })
        return this.addresses || []
      },
      set() {},
    },
  },

  methods: {
    setConvertedAddresses(index) {
      this.editedItem.addresses[index] = {}
      const addressArr = this.addresses[index].value.split(', ')
      console.log(this.convertedAddresses)
      this.$set(this.editedItem.addresses[index], 'country', 'Україна')
      this.$set(this.editedItem.addresses[index], 'city', 'Київ')
      this.$set(this.editedItem.addresses[index], 'street', addressArr[2])
      this.$set(this.editedItem.addresses[index], 'building_number', addressArr[3])
      console.log(this.editedItem)
    },
  },
}
