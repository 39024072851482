import { render, staticRenderFns } from "./PersonalContact.vue?vue&type=template&id=09e46452&scoped=true"
import script from "./PersonalContact.vue?vue&type=script&lang=js"
export * from "./PersonalContact.vue?vue&type=script&lang=js"
import style0 from "./PersonalContact.vue?vue&type=style&index=0&id=09e46452&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "09e46452",
  null
  
)

export default component.exports